import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import ImageDivider from "../ImageDivider";

const SectionTitle = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1;
  max-width: 100%;
  text-align: center;
  margin-top: 40px;

  .text-header {
    font-family: "ProximaNova-Bold";
    font-size: 48px;
    color: white;
    padding-top: 10px;
    margin-bottom: 20px;
  }

  .text-description {
    font-size: 24px;
    font-family: "ProximaNova-Bold";
    color: white;
    width: 100%;
    max-width: 600px;
    margin: 32px auto;
  }

  .text-caption {
    font-family: "ProximaNova-Regular";
    letter-spacing: 5px;
    color: white;
    line-height: 15.6px;
    margin-bottom: 32px;
  }

  .text-miniheader {
    margin-bottom: 32px;
    text-align: center;
    color: white;
  }

  &.theme-dark {
    .text-header {
      color: ${colors.petricite};
    }
    .text-miniheader {
      color: ${colors.petricite};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin-top: 36px;
    svg,
    .text-header,
    .text-miniheader {
      margin-bottom: 16px;
    }
    .text-header {
      font-size: 28px;
      margin-bottom: 48px;
    }
    .text-caption {
      font-size: 20px;
    }
    .text-description {
      font-size: 24px;
      max-width: 480px;
      margin: 28px auto;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    margin-bottom: 16px;
    margin-top: 24px;

    svg {
      width: 100%;
      height: 50px;
      margin-bottom: 8px;
    }
    .text-header {
      margin-bottom: 20px;
      font-size: 28px;
    }
    .text-description {
      font-size: 16px;
      margin: 24px auto;
    }
    .text-caption {
      font-size: 12px;
      line-height: 5px;
    }
    .text-miniheader {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  colorTheme: "light" | "dark";
  heading: string;
  subheading: string;
  description: string;
}

export default class PurchaseTitle extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const colorClass = `theme-${this.props.colorTheme}`;

    return (
      <SectionTitle className={colorClass}>
        <ImageDivider />
        <div>
          <div className="text-header">{this.props.heading}</div>

          <p className="text-caption">{this.props.subheading}</p>
        </div>
        {this.props.children && <div className="text-miniheader">{this.props.children}</div>}
      </SectionTitle>
    );
  }
}
