import React from "react";
import styled from "styled-components";
import { colors, breakpoints, eases } from "../../styles/variables";

export const PurchaseSection = styled.section`
  color: #fff;
  background-color: ${colors.petricite};

  .sword,
  .flag {
    position: absolute !important;
    z-index: 2;
    pointer-events: none;
  }

  .sword {
    left: 0;
    bottom: 0;
    width: 300px;
    transform: translate(-69%, 0%);
  }

  .flag {
    right: 0;
    top: 0;
    width: 275px;
    transform: translate(50%, 12.5%);
  }

  &.withBackground {
    padding: 0;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    .gridContainer {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  .bgFade {
    padding: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 200px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(163, 82, 87, 1) 100%);
    background-size: cover;
  }
  .instruction {
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .largeNumber {
    font-family: "ProximaNova-Bold";
    font-size: 72px;
    line-height: 10%;
    color: ${colors.orange};
    margin: 0 40px 0 0;
    vertical-align: text-top;
  }
  .consoleButtonRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    padding-bottom: 25px;
  }
  .boxType {
    background-color: ${colors.gold};
    grid-column: 1 / span 12;
    border: 4px solid ${colors.petricite};
    box-shadow: 8px 8px 0 ${colors.petricite};
    display: flex;
    flex-direction: column;
    padding: 72px;
  }
  .editionBox + .editionBox {
    margin-top: 60px;
  }

  .disclaimer {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }

  // Overall layout
  .gridOut {
    min-height: 100%;
    padding-bottom: 7.5vw;
  }
  .gridIn {
    position: relative;
    grid-column: 1 / span 12;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(180deg, #353d6f 80.01%, #5865b5 110.04%);
    border: 1px solid ${colors.periwinkle};
  }

  .layoutBox {
    width: 100%;
    grid-column: 1 / span 12;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideBox {
    width: 100%;
    background-color: ${colors.orange};
    position: relative;
    margin-bottom: 64px;
  }

  // Tabs section
  .topNavDropdown {
    display: none;
  }
  .topNavTabs {
    display: block;
    margin-bottom: 50px;
    background-color: ${colors.steam};
    display: flex;
    align-items: center;
    justify-content: space-around;
    -webkit-justify-content: space-evenly !important;
    height: 50px;
    position: relative;

    .tabOuter {
      width: 16.66%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 4px solid ${colors.periwinkle};

      .tab-text {
        color: ${colors.periwinkle};
        transition: color 0.5s ease-out;
        font-size: 16px;
        line-height: 1.7;
        font-weight: normal;
        text-transform: uppercase;

        @media (max-width: ${breakpoints.tablet}) {
          font-size: 10px;
        }
      }
      &.active {
        .tab-text {
          color: ${colors.gray};
        }
      }
    }
    .tabHighlight {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16.66%;
      height: 4px;
      background-color: ${colors.gray};
      transition: transform 0.5s ${eases.inOut};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .sword,
    .flag {
      display: none !important;
    }
    .instruction {
      margin-bottom: 25px;
    }
    .boxType {
      grid-column: 1 / span 8;
      padding: 32px;
    }
    .gridIn {
      height: 100%;
      border: none;
      background: none;
    }
    .gridOut {
      padding-top: 100px;
    }
    .topNavDropdown {
      display: block;
      padding-bottom: 20px;

      svg {
        margin-bottom: 0;

        &:hover {
          color: ${colors.gray};
        }
      }
    }
    .topNavTabs {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding-bottom: 50px;
    .lightning {
      display: none !important;
    }
    .largeNumber {
      display: block;
      line-height: 100%;
      font-size: 56px;
    }
    .instruction {
      margin-bottom: 32px;
    }
    .consoleButtonRow {
      padding: 0px;
    }
    .boxType {
      grid-column: 1 / span 2;
      padding: 20px;
    }
    .gridIn {
      height: 100%;
      border: none;
      background: none;
    }
    .gridOut {
      padding-top: 32px;
    }

    .consoleButtonRow {
      > a {
        grid-column: span 6;
      }

      > a:nth-child(1),
      > a:nth-child(2),
      > a:nth-child(3) {
        grid-column: span 12;
      }
    }
  }
`;
