import React, { useRef } from "react";
import { ContentstackImg } from "@riotgames/wwpub-components";
import ButtonConsole from "./ButtonConsole";
import { MobileOSButtonConsole } from "./MobileOSButtonConsole";
import { GameEdition, PrimaryPurchaseLink, SecondaryPurchaseLink, GameLogo } from "../../PurchasePage/Purchase";

import {
  PurchaseWrapper,
  PurchaseCardContainer,
  PurchasePacksContainer,
  PurchaseCardHero,
  CopyContainer,
  MobileStoreContainer,
  TransitionCopyContainer,
} from "./styles";

interface Props {
  activeItem: number;
  cardData: GameEdition;
  linksHeading: string;
  index: number;
  logo: GameLogo;
}

interface State {
  visible: boolean;
}

export interface gameData {
  gameEditions: GameEdition[];
  gameLogo: GameLogo;
}

export default function PurchaseCard(props: Props, state: State) {
  const selfRef = useRef(null);

  //opens links in new window
  const linkOut = (url: string) => {
    window.open(url, "_new");
  };

  const activeClass = props.activeItem === props.index ? "active" : "";
  const copy = props.cardData;
  const linksHeadings = props.linksHeading;

  const primaryConsoleButtons = copy.primaryPurchaseLinks.map((primaryPurchaseLink: PrimaryPurchaseLink) => (
    <ButtonConsole
      callback={() => linkOut(primaryPurchaseLink.purchaseLink.href)}
      url={primaryPurchaseLink.purchaseLink.href}
      console={primaryPurchaseLink.purchaseLink.title}
      svg={primaryPurchaseLink.platform[0].platformLogo.svg.content}
      key={primaryPurchaseLink.purchaseLink.title}
      buttonLength={copy.primaryPurchaseLinks.length}
    ></ButtonConsole>
  ));

  const primaryConsoleStyles = primaryConsoleButtons.length <= 2 ? "consoleButtonColumn" : "consoleButtonRow";

  const secondaryPurchaseLinks = copy.secondaryPurchaseLinks.map((secondaryPurchaseLink: SecondaryPurchaseLink) => (
    <MobileOSButtonConsole
      callback={() => linkOut(secondaryPurchaseLink.purchaseLink.href)}
      url={secondaryPurchaseLink.purchaseLink.href}
      console={secondaryPurchaseLink.purchaseLink.title}
      image={secondaryPurchaseLink.platform[0].buttonImage.url}
      key={secondaryPurchaseLink.purchaseLink.title}
    ></MobileOSButtonConsole>
  ));

  return (
    <PurchaseWrapper className={`${activeClass}`}>
      <PurchaseCardContainer>
        <PurchaseCardHero>
          <ContentstackImg className="card-logo" image={{ url: props.logo.url }} />
        </PurchaseCardHero>
        <CopyContainer>
          <TransitionCopyContainer ref={selfRef}>
            <h2 className="txt1">{copy.heading}</h2>
            {copy.features.description && <p className="txt2">{copy.features.description}</p>}
          </TransitionCopyContainer>
          <h3>{linksHeadings}</h3>
        </CopyContainer>

        <div className={`${primaryConsoleStyles}`}>{primaryConsoleButtons}</div>
        <hr />
        <MobileStoreContainer>
          <div className="consoleButtonRow">{secondaryPurchaseLinks}</div>
        </MobileStoreContainer>
      </PurchaseCardContainer>
      <PurchasePacksContainer>
        <ContentstackImg className="purchase-desktop" image={{ url: copy.features.desktopImage.url }} />
        <ContentstackImg className="purchase-mobile" image={{ url: copy.features.mobileImage.url }} />
      </PurchasePacksContainer>
    </PurchaseWrapper>
  );
}
